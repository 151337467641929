import React, { ReactNode } from 'react';
import { createStyles, MantineProvider } from '@mantine/core';
import { Helmet } from 'react-helmet';

import { graphql, useStaticQuery } from 'gatsby';
import theme from '../../theme';
import Footer from '../Footer';

interface Props {
  children: ReactNode;
}

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'space-between',
  },
}));

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        image
        twitterUsername
        url
      }
    }
  }
`;

export default function Layout({ children }: Props): JSX.Element {
  const { classes } = useStyles();
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(query);

  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <div className={classes.container}>
        <Helmet>
          <title>{site?.siteMetadata?.title}</title>
          <meta name="description" content={site?.siteMetadata?.description} />
          <link rel="canonical" href={site?.siteMetadata?.url} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={site?.siteMetadata?.title} />
          <meta property="og:description" content={site?.siteMetadata?.description} />
          <meta property="og:url" content={site?.siteMetadata?.url} />
          <meta property="og:site_name" content="Codebie" />
          <meta
            property="og:image"
            content={`${process.env.GATSBY_SITE_URL || ''}${site?.siteMetadata?.image || ''}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={site?.siteMetadata?.twitterUsername} />
        </Helmet>
        <main>{children}</main>
        <Footer />
      </div>
    </MantineProvider>
  );
}
