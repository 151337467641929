import React, { ReactNode } from 'react';
import { Container, DefaultProps, Text, Title } from '@mantine/core';

import useStyles from './PrivateBeta.styles';
import JoinWaitlist from '../JoinWaitlist';

interface Props extends DefaultProps {
  badge: string;
  title: string;
  description: ReactNode;
}

export default function PrivateBeta({ badge, title, description, ...rest }: Props): JSX.Element {
  const { classes } = useStyles();

  return (
    <Container {...rest}>
      <Text size="sm" transform="uppercase" color="violet" weight="700" align="center">
        {badge}
      </Text>
      <Title order={2} align="center" mt="sm">
        {title}
      </Title>
      <Text align="center" mt="xs" mb="lg" className={classes.description}>
        {description}
      </Text>
      <JoinWaitlist className={classes.waitList} />
    </Container>
  );
}
