import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  description: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  waitList: {
    maxWidth: '670px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
