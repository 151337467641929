import React from 'react';
import { createStyles, Container, Group, ActionIcon } from '@mantine/core';
import { IconBrandTwitter, IconBrandInstagram, IconBrandGithub } from '@tabler/icons';

import Logo from '../Logo';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[0],
    marginTop: 'auto',
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function Footer(): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Logo width={129} height={25} />
        <Group spacing={0} className={classes.links} position="right" noWrap>
          <ActionIcon size="lg" component="a" href="https://github.com/codebiecom">
            <IconBrandGithub size={18} />
          </ActionIcon>
          <ActionIcon size="lg" component="a" href="https://twitter.com/codebiecom">
            <IconBrandTwitter size={18} />
          </ActionIcon>
          <ActionIcon size="lg" component="a" href="https://instagram.com/codebieapp">
            <IconBrandInstagram size={18} />
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
}
