import { Button, DefaultProps, Input, Grid, Alert } from '@mantine/core';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';

type Props = DefaultProps;

export default function JoinWaitlist({ ...rest }: Props): JSX.Element {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      setLoading(true);

      try {
        const response = await fetch(process.env.GATSBY_WAIT_LIST_URL || '', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        const data = (await response.json()) as { status: boolean };

        if (data.status) {
          setSuccess(true);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
    },
    [email],
  );

  if (success) {
    return <Alert color="green">Thank you! We&apos;ll you back very shortly.</Alert>;
  }

  if (error) {
    return <Alert color="red">An error occurred while saving your e-mail</Alert>;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form {...rest} onSubmit={handleSubmit}>
      <Grid gutter="xs" grow>
        <Grid.Col sm={9}>
          <Input
            type="email"
            placeholder="Your work e-mail address"
            size="md"
            required
            value={email}
            onChange={handleEmailChange}
          />
        </Grid.Col>
        <Grid.Col sm={3}>
          <Button disabled={loading} type="submit" size="md" fullWidth>
            Join to wait list
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
