import { Button, Group } from '@mantine/core';
import React from 'react';
import Logo from '../Logo';

export default function Header(): JSX.Element {
  return (
    <Group position="apart" mb="xl">
      <Logo width={154} height={30} />
      <Button component="a" href={process.env.GATSBY_PLATFORM_URL} size="md">
        Go to app
      </Button>
    </Group>
  );
}
