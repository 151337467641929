import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    background: theme.fn.linearGradient(180, theme.colors.gray[1], theme.colors.gray[2]),
  },

  info: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default useStyles;
