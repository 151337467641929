import React from 'react';
import { Anchor, Image } from '@mantine/core';

import { Link } from 'gatsby';
import logo from '../../images/logo.svg';

interface Props {
  width: number;
  height: number;
}

export default function Logo({ width, height }: Props): JSX.Element {
  return (
    <Anchor component={Link} to="/">
      <Image width={width} height={height} src={logo} alt="Codebie" />
    </Anchor>
  );
}
