import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  primaryColor: 'violet',
  spacing: {
    xl: 48,
    lg: 32,
    md: 24,
    sm: 16,
    xs: 8,
  },
  headings: {
    sizes: {
      h1: { fontSize: 42, lineHeight: 1.15 },
      h2: { fontSize: 32, lineHeight: 1.15 },
    },
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
  },
};

export default theme;
