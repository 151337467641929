import React, { ReactNode } from 'react';
import { Box, Container, Text, Title, Image } from '@mantine/core';

import useStyles from './Hero.styles';
import Header from '../Header';
import JoinWaitlist from '../JoinWaitlist';

interface Props {
  title: string;
  description: ReactNode;
  image: string;
}

export default function Hero({ title, description, image }: Props): JSX.Element {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Container size="xl" py="xl">
        <Header />

        <Box className={classes.info}>
          <Title order={1} align="center">
            {title}
          </Title>
          <Text size="md" mt="sm" align="center">
            {description}
          </Text>
        </Box>

        <Container size="sm">
          <JoinWaitlist />
        </Container>

        <Image mt="xl" radius="md" src={image} />
      </Container>
    </Box>
  );
}
