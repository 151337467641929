import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { TypographyStylesProvider } from '@mantine/core';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero';
import PrivateBeta from '../components/PrivateBeta';

export default function HomePage({ data }: PageProps<GatsbyTypes.HomePageQueryQuery>): JSX.Element {
  return (
    <>
      <Helmet>
        <title>
          {data.site?.siteMetadata?.title} - {data.site?.siteMetadata?.description}
        </title>
      </Helmet>
      <Layout>
        <Hero
          title={data.contentfulHero?.title || ''}
          description={
            data.contentfulHero?.description?.raw && (
              <TypographyStylesProvider>
                {renderRichText({ raw: data.contentfulHero?.description.raw, references: [] })}
              </TypographyStylesProvider>
            )
          }
          image={data.contentfulHero?.image?.publicUrl || ''}
        />
        <PrivateBeta
          py="xl"
          badge={data.contentfulClosedPreview?.badge || ''}
          title={data.contentfulClosedPreview?.title || ''}
          description={data.contentfulClosedPreview?.description?.description || ''}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    contentfulHero {
      title
      description {
        raw
      }
      image {
        publicUrl
      }
    }

    contentfulClosedPreview {
      badge
      title
      description {
        description
      }
    }
  }
`;
